import { useState, useEffect, useCallback } from 'react'
import { getApiDomainConf } from '@blocks-helpers/getApiDomainConf'
const useForm = () => {
  const [submitting, setSubmitting] = useState(false)
  const [target, setTarget] = useState({})
  const [success, setSuccess] = useState()
  const [values, setValues] = useState()
  const [action, setAction] = useState()
  const apiList = getApiDomainConf('*')
  const handleSubmit = (e, { values, action } = {}) => {
    e.preventDefault()
    if (values) setValues(values)
    if (action) setAction(action)
    setTarget(e.target)
    setSubmitting(true)
  }

  const sendValues = useCallback(() => {
    const form = new FormData(target)
    const formData = new URLSearchParams(values || form).toString()
    const isDemo = target.getAttribute('demo')

    // Mimicking form submission for demos
    if (isDemo) {
      setTimeout(() => {
        setSuccess(true)
        setSubmitting(false)
      }, 1500)
      return
    }

    let _action = action || target.action
    let _actionArray = _action.split('/')
    let status = false

    for (let i = 0; i < apiList.length; i++) {
      if (apiList[i].name == _actionArray[0]) {
        status = true
      }
    }

    if (status) {
      _action = _action.replace(_actionArray[0], '')
      var isRegistered = 0
      if (_action == '/user/ajaxRegister') {
        isRegistered = 1
      }
      _action = getApiDomainConf(_actionArray[0], apiList) + _action
    } else {
      _action = action || target.action
    }

    let headers = new Headers()

    headers.append('Content-Type', 'application/x-www-form-urlencoded')
    headers.append('Accept', 'application/json')
    // Real form submission

    fetch(_action, {
      method: target.method || 'POST',
      headers: headers,
      credentials: 'same-origin', // include, *same-origin, omit,
      body: formData
    })
      .then(r => {
        if (r.status != 200 && false) {
          setSuccess(false)
        } else {
          const contentType = r.headers.get('content-type')
          if (contentType && contentType.indexOf('application/json') === -1) {
            setSuccess(true)
            return r.text().then(text => {
              alert(text)
            })
          } else {
            r.json().then(function (r) {
              target.reset()
              if (r.status) {
                setSuccess(true)
              } else if (!r.status) {
                setSuccess(false)
              }

              if (r.message !== undefined) {
                alert(r.message)
              } else if (r.redirect !== undefined) {
                if (
                  _action ==
                  getApiDomainConf('biwaps_user', apiList) + '/user/ajaxLogin'
                ) {
                  window.location.href =
                    getApiDomainConf('biwaps_user', apiList) +
                    '/user/ajaxGetLogin?' +
                    formData
                } else {
                  if (isRegistered) {
                    function gtag_report_conversion(url) {
                      var callback = function () {
                        if (typeof url != 'undefined') {
                          window.location = url
                        }
                      }
                      gtag('event', 'conversion', {
                        send_to: 'AW-673297262/BKbBCIKg98EBEO7mhsEC',
                        value: 1.0,
                        currency: 'TRY',
                        event_callback: callback
                      })
                      return false
                    }
                    gtag_report_conversion(
                      getApiDomainConf('biwaps_user', apiList) + r.redirect
                    )
                  }
                }
              }
            })
          }
        }
      })
      .catch(error => {
        //setSuccess(false)
      })
      .finally(() => {
        setSubmitting(false)
      })
  }, [target])

  useEffect(() => {
    if (submitting) {
      sendValues()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitting, sendValues])

  const reset = useCallback(() => {
    setSubmitting(false)
    setSuccess(undefined)
    setValues(undefined)
    setAction(undefined)
  }, [])

  return {
    handleSubmit,
    submitting,
    success,
    reset
  }
}

export default useForm
