// Importing default theme color object to mutate it
import defaultColors from '@elegantstack/solid-ui-theme/src/colors'

// Using tailwind preset colors
import colors from '@elegantstack/solid-ui-theme/src/color-preset'

const a = {
  ...defaultColors,
    // Alpha (primary)
    alphaLighter: colors.blueGray[200],
    alphaLight: colors.blueGray[300],
    alpha: colors.blueGray[700],
    alphaDark: colors.blueGray[800],
    alphaDarker: colors.blueGray[900],
    // beta (secondary)
    betaLighter: colors.lightBlue[100],
    betaLight: colors.lightBlue[300],
    beta: colors.lightBlue[400],
    betaDark: colors.lightBlue[600],
    betaDarker: colors.lightBlue[800]
}

export default a