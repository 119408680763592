import { React, useEffect, useState } from 'react'
import { Global } from '@emotion/core'
import { ThemeProvider, merge, Flex, Box, css } from 'theme-ui'
import baseTheme from '@solid-ui-theme'
import pageContextProvider from '@helpers/pageContextProvider'
import { FormContextProvider } from '@solid-ui-components/ContentForm'
import { ModalContextProvider } from '@solid-ui-components/Modal'
import { TabsContextProvider } from '@solid-ui-components/Tabs'
import ColorMode from '@solid-ui-components/ColorMode'
import { getApiDomainConf } from '@blocks-helpers/getApiDomainConf'
import { YMInitializer } from 'react-yandex-metrika'
import Favicon from 'react-favicon'
const Layout = ({ children, pageContext = {}, location, theme = {} }) => {
  // It takes a function
  useEffect(() => {
    import('react-facebook-pixel')
      .then(module => module.default)
      .then(ReactPixel => {
        ReactPixel.init('190961795487256', {}, {})
        ReactPixel.pageView()
      })
    const script = document.createElement('script')

    script.src = 'https://www.googletagmanager.com/gtag/js?id=AW-673297262'
    script.async = true

    document.body.appendChild(script)
    const s = document.createElement('script')
    s.type = 'text/javascript'
    s.async = true
    s.innerHTML =
      "window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);};gtag('js', new Date());gtag('config', 'AW-673297262');"
    document.body.appendChild(s)

    return () => {
      document.body.removeChild(script)
    }
  }, [])
  const favicon =
    require('../../../../../site/content/assets/favicon.ico').default
  const api = getApiDomainConf('biwaps_user')
  const isAjax = api + '/user/ajaxIs'
  useEffect(() => {
    var Tawk_API = Tawk_API || {},
      Tawk_LoadStart = new Date()
    ;(function () {
      var s1 = document.createElement('script'),
        s0 = document.getElementsByTagName('script')[0]
      s1.async = true
      s1.src = 'https://embed.tawk.to/5e2320c58e78b86ed8a9f468/default'
      s1.charset = 'UTF-8'
      s1.setAttribute('crossorigin', '*')
      s0.parentNode.insertBefore(s1, s0)
    })()

    let headers = new Headers()
    headers.append('Content-Type', 'application/x-www-form-urlencoded')
    headers.append('Accept', 'application/json')
    fetch(isAjax, {
      headers: headers,
      credentials: 'include'
    })
      .then(res => res.json())
      .then(result => {
        if (result.status) {
          if (result.location) {
            window.location.href = result.location
          } else {
            window.location.href = api
          }
        }
      })
  }, [])

  return (
    <ThemeProvider theme={merge(baseTheme, theme)}>
      <Favicon url={favicon} />

      <pageContextProvider.Provider value={{ pageContext, location }}>
        <FormContextProvider>
          <ModalContextProvider>
            <TabsContextProvider>
              <Flex variant='layout.layout'>
                <Global styles={css(theme => theme.global)} />
                {/*<ColorMode />*/}
                <Box variant='layout.body'>{children}</Box>
              </Flex>
            </TabsContextProvider>
          </ModalContextProvider>
        </FormContextProvider>
      </pageContextProvider.Provider>
      <YMInitializer accounts={[86202773]} />
    </ThemeProvider>
  )
}

export default Layout
