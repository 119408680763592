import useSiteMetadata from './useSiteMetadata'

export const getApiDomainConf = (key = '*', apiList = null) => {
  if (apiList == null) {
    apiList = useSiteMetadata().apiDomainList
  }

  if (key == '*') {
    return apiList
  }
  return apiList.find(x => x.name === key).url
}
